import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography, Button } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'
import { CheckOutlined } from '@ant-design/icons'
import FreeTrialCta from '../components/free-trial-cta'

const { Title, Paragraph, Text } = Typography

const Compta4You = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "homepage-compta4you.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      banksynchroImage: file(relativePath: { eq: "rocketchart-connect-bank-account-to-track-cash-in-and-cash-out-in-real-time.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      decisionImage: file(relativePath: { eq: "simulate-business-decisions-to-make-better-decision.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const coverImage = 'https://i.imgur.com/ZNSWJyB.png'
  const description = "RocketChart et Compta4You s'associent pour faciliter votre gestion d'entreprise, de la comptabilité à la trésorerie."

  return (
    <Layout>
      <Head
        title="RocketChart et Compta4You"
        description={description}
        canonical="https://rocketchart.co/rocketchart-compta4you/"
        meta={[
          {
            property: 'og:image',
            content: coverImage
          },
          {
            name: 'twitter:image',
            content: coverImage
          }
        ]}
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={24} lg={10}>
          <Title>Compta4You & RocketChart</Title>
          <Paragraph>Votre cabinet d’expertise-comptable Compta4You et le logiciel de trésorerie RocketChart s’associent pour vous proposer une solution complète de gestion d'entreprise, de la comptabilité à la trésorerie.</Paragraph>
          <a href="rocketchart-compta4you#offre">
            <Button size='large' type="primary" className="rc-cta">Découvrir l'offre spéciale</Button>
          </a>
        </Col>
        <Col xs={24} lg={14} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="RocketChart et Compta4You" className="rounded-image" />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center">
        <Col xs={24} md={16} style={{ marginTop: '80px' }}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '80px', marginBottom: '80px' }}>
          <Title level={2}>
            Gérez votre trésorerie en temps réel
            <br />
            avec des projections fiables.
          </Title>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.banksynchroImage.childImageSharp.gatsbyImageData} alt="Gérez votre trésorerie en temps réel." />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={3}>Gagnez du temps avec un suivi de trésorerie automatisé en temps réel.</Title>
          <Paragraph>Connectez votre banque et vos outils de facturation pour mettre à jour votre trésorerie automatiquement. Ne vous contentez plus d'un rapport de trésorerie de fin de mois. Suivez l'évolution de votre trésorerie au jour le jour sur une interface claire et personnalisée.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={3}>Gagnez en visibilité avec des prévisions de trésorerie fiables et sans erreur.</Title>
          <Paragraph id='offre'>Anticipez votre trésorerie pour prendre les bonnes décisions business. Réalisez facilement votre plan de trésorerie prévisionnelle et simulez l'impact d'un scénario sur votre trésorerie : variation de CA, investissements, recrutements, remboursement de prêt, ...  </Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.decisionImage.childImageSharp.gatsbyImageData} alt="Anticipez votre trésorerie prévisionnelle." />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '50px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={24} md={16}>
          <div className="rc-attention-container" loading='lazy'>
            <Title level={2} align='center' style={{ marginBottom: '50px' }}>
              Vous êtes client Compta4You ?
              <br />
              Profitez d'une offre exclusive.
            </Title>
            <Paragraph><CheckOutlined className='rc-large-text rc-green-text' /> Jusqu'à <Text strong>40% de réduction</Text> sur votre abonnement mensuel</Paragraph>
            <Paragraph><CheckOutlined className='rc-large-text rc-green-text' /> <Text strong>15 jours d'essai gratuit</Text> sans engagement</Paragraph>
            <Paragraph><CheckOutlined className='rc-large-text rc-green-text' /> Demandez le code de réduction à votre interlocuteur Compta4You</Paragraph>
          </div>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '50px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px', marginBottom: '50px' }}>
          <Title level={2}>
            Créez un compte
            <br />
            Profitez de l'offre dès maintenant
          </Title>

          <FreeTrialCta label="Ouvrir un compte" rm="-_cmYjFlX" />
        </Col>
      </Row>
    </Layout>
  )
}

export default Compta4You
